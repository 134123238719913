import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [0,3,5,6,8,9,14];

export const dictionary = {
		"/": [~15],
		"/admin": [~27,[3]],
		"/admin/affiliates": [~28,[3,4]],
		"/admin/affiliates/invoices": [~29,[3,4]],
		"/admin/client-onboarding": [30,[3]],
		"/admin/clients": [~31,[3]],
		"/admin/clients/[clientId]": [~32,[3,5]],
		"/admin/clients/[clientId]/employees": [~33,[3,5]],
		"/admin/clients/[clientId]/employees/[employeeId]": [~34,[3,5,6]],
		"/admin/clients/[clientId]/invoices": [~35,[3,5]],
		"/admin/clients/[clientId]/orders": [36,[3,5]],
		"/admin/clients/[clientId]/profile": [~37,[3,5]],
		"/admin/clients/[clientId]/subscriptions": [~38,[3,5]],
		"/admin/clients/[clientId]/units": [39,[3,5]],
		"/admin/invoices": [~40,[3,7]],
		"/admin/invoices/draft": [~41,[3,7]],
		"/admin/invoices/open": [~42,[3,7]],
		"/admin/invoices/overdue": [~43,[3,7]],
		"/admin/jobs": [44,[3]],
		"/admin/orders": [~45,[3]],
		"/admin/orders/[status]": [~46,[3]],
		"/admin/overview": [47,[3]],
		"/admin/system-information": [~48,[3]],
		"/admin/tags": [~49,[3]],
		"/affiliate": [~50,[8]],
		"/affiliate/dashboard": [~51,[8]],
		"/app": [~52,[9]],
		"/app/company/card-designs": [~65,[9]],
		"/app/company/contacts": [~66,[9]],
		"/app/company/orders": [~67,[9]],
		"/app/company/overview": [~68,[9]],
		"/app/company/referral": [~69,[9]],
		"/app/company/settings": [~70,[9,11]],
		"/app/company/settings/billing-address": [~71,[9,11]],
		"/app/company/settings/company": [~72,[9,11]],
		"/app/company/settings/integrations": [~73,[9,11]],
		"/app/company/settings/invoices-and-billing": [~74,[9,11]],
		"/app/[unitId]": [~53,[9]],
		"/app/[unitId]/contacts": [~54,[9]],
		"/app/[unitId]/design": [~55,[9]],
		"/app/[unitId]/employees": [~56,[9]],
		"/app/[unitId]/orders": [~57,[9]],
		"/app/[unitId]/overview": [~58,[9]],
		"/app/[unitId]/pass-design": [~59,[9]],
		"/app/[unitId]/profile": [~60,[9]],
		"/app/[unitId]/settings": [~61,[9,10]],
		"/app/[unitId]/settings/employees": [~62,[9,10]],
		"/app/[unitId]/settings/profile": [~63,[9,10]],
		"/app/[unitId]/settings/unit": [~64,[9,10]],
		"/callback/[type]": [~75],
		"/(auth)/(auth-guard)/confirm-email-change": [~16,[2]],
		"/get-started": [~76,[12]],
		"/get-started/1-choose-type": [77,[12]],
		"/get-started/2-setup-profile": [78,[12]],
		"/get-started/3-setup-company-profile": [~79,[12]],
		"/get-started/4-agree-terms": [~80,[12]],
		"/(auth)/(no-auth-guard)/login": [~21,[2]],
		"/(auth)/(auth-guard)/logout": [~17,[2]],
		"/profile/(protected)/analytics": [~81,[13]],
		"/profile/(protected)/billing-address": [~82,[13]],
		"/profile/(protected)/contacts": [~83,[13]],
		"/profile/(protected)/contacts/[...contactId]": [~84,[13]],
		"/profile/deleted": [90,[13]],
		"/profile/(protected)/invoice-and-billing": [~85,[13]],
		"/profile/premium-feature": [91,[13]],
		"/profile/(protected)/settings": [~86,[13]],
		"/profile/(protected)/tags": [~87,[13]],
		"/profile/(protected)/tags/[...tagId]": [~88,[13]],
		"/profile/[...profileId]": [~89,[13]],
		"/(auth)/(auth-guard)/redirect-user": [~18,[2]],
		"/(auth)/(auth-guard)/refresh-session": [~19,[2]],
		"/(auth)/(no-auth-guard)/register": [~22,[2]],
		"/(auth)/(no-auth-guard)/register/confirm": [23,[2]],
		"/(auth)/(no-auth-guard)/request-magic-link": [~24,[2]],
		"/(auth)/(no-auth-guard)/reset-password": [~25,[2]],
		"/(auth)/set-password": [~26,[2]],
		"/supplier": [~92,[14]],
		"/supplier/orders": [~93,[14]],
		"/t/[tagId]": [~94],
		"/(auth)/(auth-guard)/update-account": [~20,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';