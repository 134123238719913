import { PUBLIC_API_ENDPOINT, PUBLIC_ENVIRONMENT } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://9aaccfc144ad6112511b8dc3486257f9@o4508319091195904.ingest.de.sentry.io/4508319098142800',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [replayIntegration()],
  tunnel: `${PUBLIC_API_ENDPOINT}/tunnel`,
  enabled: PUBLIC_ENVIRONMENT !== 'local',
  environment: PUBLIC_ENVIRONMENT,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
